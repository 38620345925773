var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"board",style:({
    '--categories': _vm.categories.length,
    '--points': _vm.points.length,
    '--teams': _vm.teams.length,
    '--current-team-color': _vm.currentFullTeam.color
  })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.finished),expression:"finished"}],staticClass:"board__finished"},[_c('div',{staticClass:"board__finished-announcement"},[_vm._v(_vm._s(_vm.winnerAnnouncement))]),_c('div',{ref:"fireworks",staticClass:"board__finished-fireworks"})]),_c('div',{staticClass:"board__header"},_vm._l((_vm.fullTeams),function(team){return _c('div',{key:team.name,staticClass:"board__team",class:{
        'board__team--turn': team.turn
      },style:({
        '--team-color': team.color,
        color: team.textColor
      })},[_c('span',[_c('v-icon',{staticClass:"board__turn-indicator fontsize-icon",style:({ visibility: _vm.finished ? 'hidden' : 'visible' })},[_vm._v("mdi-arrow-right-thick")]),_vm._v(" "+_vm._s(team.name))],1),_c('span',{style:({ width: ((String(_vm.maxPointsPerTeam).length + 1) + "ch") })},[_vm._v(_vm._s(team.points))])])}),0),_vm._l((_vm.categories),function(category,categoryIndex){return [_c('div',{key:(category + "-head"),staticClass:"board__category-head",style:({
        'grid-row': "2 / span 1",
        'grid-column': ((1 + categoryIndex) + " / span 1")
      })},[_vm._v(" "+_vm._s(category)+" ")]),_vm._l((_vm.points),function(amount,amountIndex){return _c('button',{key:(category + "-" + amount),staticClass:"board__field",class:{
        'board__field--active': _vm.activeQuestion === (category + "-" + amount),
        'board__field--answered': (category + "-" + amount) in _vm.answers,
        'board__field--omitted':
          !((category + "-" + amount) in _vm.answers) && _vm.finished,
        'board__field--answered-correct':
          (category + "-" + amount) in _vm.answers &&
          _vm.answers[(category + "-" + amount)].correct,
        'board__field--answered-incorrect':
          (category + "-" + amount) in _vm.answers &&
          !_vm.answers[(category + "-" + amount)].correct
      },style:({
        '--answered-background':
          (category + "-" + amount) in _vm.answers
            ? _vm.fullTeams[_vm.answers[(category + "-" + amount)].team].color
            : null,
        '--answered-foreground':
          (category + "-" + amount) in _vm.answers
            ? _vm.fullTeams[_vm.answers[(category + "-" + amount)].team].textColor
            : null,
        'grid-row': ((3 + amountIndex) + " / span 1"),
        'grid-column': ((1 + categoryIndex) + " / span 1")
      }),on:{"click":function($event){return _vm.markQuestionActive((category + "-" + amount))}}},[_c('v-btn',{staticClass:"board__field-confirm board__field-confirm--correct",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.markQuestionAnswered((category + "-" + amount), _vm.teamTurnIndex, true)}}},[_c('v-icon',{staticClass:"fontsize-icon"},[_vm._v("mdi-check-outline")])],1),_c('span',{staticClass:"board__field-points"},[_vm._v(_vm._s(amount))]),_c('v-btn',{staticClass:"board__field-confirm board__field-confirm--incorrect",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.markQuestionAnswered((category + "-" + amount), _vm.teamTurnIndex, false)}}},[_c('v-icon',{staticClass:"fontsize-icon"},[_vm._v("mdi-close-outline")])],1),_c('v-btn',{staticClass:"board__field-distribute",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.distributePoints(category, amount, _vm.teamTurnIndex)}}},[_c('v-icon',{staticClass:"fontsize-icon"},[_vm._v("mdi-dots-horizontal")])],1)],1)})]}),(_vm.distributionMode)?_c('div',{staticClass:"board__distribute"},[_c('span',{staticClass:"board__distribute-headline"},[_vm._v(_vm._s(_vm.distributionMode.amount)+" Punkte verteilen")]),_c('div',{staticClass:"board__distribute-teams"},_vm._l((_vm.fullTeams),function(team){return _c('button',{key:team.name,staticClass:"board__distribute-team",class:{
          'board__distribute-team--active': _vm.distributionMode.targets.includes(
            team.name
          )
        },style:({
          '--team-color': team.color,
          color: team.textColor
        }),on:{"click":function($event){return _vm.distributeToggleTeam(team)}}},[_vm._v(" "+_vm._s(team.name)+" ")])}),0),_c('div',{staticClass:"board__distribute-finish"},[_c('v-btn',{staticClass:"board__distribute-button board__distribute-cancel",attrs:{"icon":"","x-large":""},on:{"click":function($event){$event.stopPropagation();_vm.distributionMode = null}}},[_c('v-icon',{staticClass:"fontsize-icon"},[_vm._v("mdi-close-outline")])],1),_c('v-btn',{staticClass:"board__distribute-button board__distribute-confirm",attrs:{"icon":"","x-large":""},on:{"click":function($event){$event.stopPropagation();return _vm.distributeConfirm()}}},[_c('v-icon',{staticClass:"fontsize-icon"},[_vm._v("mdi-check-outline")])],1)],1)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }