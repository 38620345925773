<template>
  <div
    class="board"
    :style="{
      '--categories': categories.length,
      '--points': points.length,
      '--teams': teams.length,
      '--current-team-color': currentFullTeam.color
    }"
  >
    <div class="board__finished" v-show="finished">
      <div class="board__finished-announcement">{{ winnerAnnouncement }}</div>
      <div class="board__finished-fireworks" ref="fireworks" />
    </div>
    <div class="board__header">
      <div
        v-for="team in fullTeams"
        :key="team.name"
        class="board__team"
        :class="{
          'board__team--turn': team.turn
        }"
        :style="{
          '--team-color': team.color,
          color: team.textColor
        }"
      >
        <span>
          <v-icon
            class="board__turn-indicator fontsize-icon"
            :style="{ visibility: finished ? 'hidden' : 'visible' }"
            >mdi-arrow-right-thick</v-icon
          >
          {{ team.name }}</span
        >
        <span :style="{ width: `${String(maxPointsPerTeam).length + 1}ch` }">{{
          team.points
        }}</span>
      </div>
    </div>
    <template v-for="(category, categoryIndex) in categories">
      <div
        class="board__category-head"
        :key="`${category}-head`"
        :style="{
          'grid-row': `2 / span 1`,
          'grid-column': `${1 + categoryIndex} / span 1`
        }"
      >
        {{ category }}
      </div>
      <button
        v-for="(amount, amountIndex) in points"
        :key="`${category}-${amount}`"
        class="board__field"
        :class="{
          'board__field--active': activeQuestion === `${category}-${amount}`,
          'board__field--answered': `${category}-${amount}` in answers,
          'board__field--omitted':
            !(`${category}-${amount}` in answers) && finished,
          'board__field--answered-correct':
            `${category}-${amount}` in answers &&
            answers[`${category}-${amount}`].correct,
          'board__field--answered-incorrect':
            `${category}-${amount}` in answers &&
            !answers[`${category}-${amount}`].correct
        }"
        :style="{
          '--answered-background':
            `${category}-${amount}` in answers
              ? fullTeams[answers[`${category}-${amount}`].team].color
              : null,
          '--answered-foreground':
            `${category}-${amount}` in answers
              ? fullTeams[answers[`${category}-${amount}`].team].textColor
              : null,
          'grid-row': `${3 + amountIndex} / span 1`,
          'grid-column': `${1 + categoryIndex} / span 1`
        }"
        @click="markQuestionActive(`${category}-${amount}`)"
      >
        <v-btn
          icon
          class="board__field-confirm board__field-confirm--correct"
          @click.stop="
            markQuestionAnswered(`${category}-${amount}`, teamTurnIndex, true)
          "
        >
          <v-icon class="fontsize-icon">mdi-check-outline</v-icon>
        </v-btn>
        <span class="board__field-points">{{ amount }}</span>
        <v-btn
          icon
          class="board__field-confirm board__field-confirm--incorrect"
          @click.stop="
            markQuestionAnswered(`${category}-${amount}`, teamTurnIndex, false)
          "
        >
          <v-icon class="fontsize-icon">mdi-close-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          class="board__field-distribute"
          @click.stop="distributePoints(category, amount, teamTurnIndex)"
        >
          <v-icon class="fontsize-icon">mdi-dots-horizontal</v-icon>
        </v-btn>
      </button>
    </template>
    <div class="board__distribute" v-if="distributionMode">
      <span class="board__distribute-headline"
        >{{ distributionMode.amount }} Punkte verteilen</span
      >
      <div class="board__distribute-teams">
        <button
          v-for="team in fullTeams"
          :key="team.name"
          class="board__distribute-team"
          :class="{
            'board__distribute-team--active': distributionMode.targets.includes(
              team.name
            )
          }"
          :style="{
            '--team-color': team.color,
            color: team.textColor
          }"
          @click="distributeToggleTeam(team)"
        >
          {{ team.name }}
        </button>
      </div>

      <div class="board__distribute-finish">
        <v-btn
          icon
          class="board__distribute-button board__distribute-cancel"
          x-large
          @click.stop="distributionMode = null"
        >
          <v-icon class="fontsize-icon">mdi-close-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          class="board__distribute-button board__distribute-confirm"
          x-large
          @click.stop="distributeConfirm()"
        >
          <v-icon class="fontsize-icon">mdi-check-outline</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import getTextColor from '../assets/get-text-color'
import * as Fireworks from 'fireworks-canvas'

function isNonEmptyString(value) {
  return typeof value === 'string' && value.trim().length > 0
}

export default {
  props: {
    points: {
      type: Array,
      validator: points =>
        points.length > 0 &&
        points.every(amount => Number.isInteger(amount) && amount > 0)
    },
    categories: {
      type: Array,
      validator: categories =>
        categories.length > 0 && categories.every(isNonEmptyString)
    },
    teams: {
      type: Array,
      validator: teams =>
        teams.length > 0 &&
        teams.every(team => {
          if (typeof team !== 'object' && team === null) {
            return false
          }

          return (
            isNonEmptyString(team.name) && /^#[a-f0-9]{6}$/i.test(team.color)
          )
        })
    },
    rules: Object
  },
  data: () => ({
    answers: {},
    turn: 1,
    activeQuestion: null,
    distributionMode: null,
    extraPoints: {}
  }),
  computed: {
    playState() {
      return {
        answers: this.answers,
        turn: this.turn,
        activeQuestion: this.activeQuestion,
        distributionMode: this.distributionMode
      }
    },
    appliedRules() {
      return {
        onFailureAwardOpponents: true,
        clampTurns: true,
        ...this.rules
      }
    },
    winningTeams() {
      if (!this.finished) {
        return null
      }

      return this.fullTeams.reduce((carry, current) => {
        if (carry.some(team => team.points > current.points)) {
          return carry
        } else if (carry.some(team => team.points === current.points)) {
          return [...carry, current]
        } else {
          return [current]
        }
      }, [])
    },
    winnerAnnouncement() {
      if (this.winningTeams === null) {
        return ''
      }

      if (this.winningTeams.length < this.teams.length) {
        if (this.winningTeams.length === 1) {
          return `${this.winningTeams[0].name} hat gewonnen!`
        } else {
          return `${this.winningTeams
            .slice(0, -1)
            .map(team => team.name)
            .join(', ')} und ${
            this.winningTeams[this.winningTeams.length - 1].name
          } haben gewonnen!`
        }
      } else {
        return 'Gleichstand!'
      }
    },
    turns() {
      const questions = this.categories.length * this.points.length

      if (this.appliedRules.clampTurns) {
        return questions - (questions % this.teams.length)
      } else {
        return questions
      }
    },
    finished() {
      return this.turn > this.turns
    },
    maxPointsPerTeam() {
      const descSortedFieldPoints = [...this.points]
        .sort()
        .reverse()
        .reduce(
          (carry, amount) =>
            carry.concat(new Array(this.categories.length).fill(amount)),
          []
        )

      const maxAnswersPerTeam = Math.ceil(
        (this.categories.length * this.points.length) / this.teams.length
      )

      return descSortedFieldPoints
        .slice(0, maxAnswersPerTeam)
        .reduce((carry, current) => carry + current, 0)
    },
    teamTurnIndex() {
      return (this.turn - 1) % this.teams.length
    },
    currentFullTeam() {
      return this.fullTeams[this.teamTurnIndex]
    },
    fullTeams() {
      return this.teams.map((team, index) => {
        return {
          ...team,
          textColor: this.teamsTextColor[index],
          points: this.teamPoints[index],
          turn: this.teamTurnIndex === index
        }
      })
    },
    teamPoints() {
      return this.teams.map((team, teamIndex) => {
        return (
          (this.extraPoints[team.name] ?? 0) +
          this.categories.reduce((carry, category) => {
            return (
              carry +
              this.points.reduce((carry, amount) => {
                const key = `${category}-${amount}`
                if (key in this.answers) {
                  const answer = this.answers[key]
                  if (answer.team === teamIndex) {
                    if (answer.correct) {
                      return carry + amount
                    } else {
                      return carry
                    }
                  } else {
                    if (
                      answer.correct === false &&
                      this.appliedRules.onFailureAwardOpponents
                    ) {
                      return (
                        carry + Math.floor(amount / (this.teams.length - 1))
                      )
                    } else {
                      return carry
                    }
                  }
                } else {
                  return carry
                }
              }, 0)
            )
          }, 0)
        )
      })
    },
    teamsTextColor() {
      return this.teams.map(team => getTextColor(team.color))
    }
  },
  watch: {
    playState: {
      handler(value) {
        this.$emit('statechange', value)
      },
      deep: true
    },
    async finished(finished) {
      // Show fireworks if some teams have won
      if (finished && this.winningTeams.length < this.teams.length) {
        await this.$nextTick()
        const container = this.$refs.fireworks
        const options = {
          maxRockets: 3, // max # of rockets to spawn
          rocketSpawnInterval: 150, // millisends to check if new rockets should spawn
          numParticles: 100, // number of particles to spawn when rocket explodes (+0-10)
          explosionMinHeight: 0.2, // percentage. min height at which rockets can explode
          explosionMaxHeight: 0.9, // percentage. max height before a particle is exploded
          explosionChance: 0.08 // chance in each tick the rocket will explode
        }
        const fireworks = new Fireworks(container, options)
        fireworks.start()
      }
    }
  },
  methods: {
    markQuestionActive(key) {
      if (key in this.answers || this.finished) {
        return
      }

      if (this.activeQuestion === key) {
        this.activeQuestion = null
      } else {
        this.activeQuestion = key
      }
    },
    markQuestionAnswered(key, team, correct) {
      this.$set(this.answers, key, { team, correct })
      this.activeQuestion = null
      this.turn++
    },
    distributePoints(category, amount, team) {
      this.distributionMode = { category, amount, team, targets: [] }
    },
    distributeToggleTeam(team) {
      if (this.distributionMode.targets.includes(team.name)) {
        this.distributionMode.targets.splice(
          this.distributionMode.targets.indexOf(team.name),
          1
        )
      } else {
        this.distributionMode.targets.push(team.name)
      }
    },
    distributeConfirm() {
      let targets = this.distributionMode.targets
      let pointsPerTarget = Math.floor(
        this.distributionMode.amount / targets.length
      )

      for (let target of targets) {
        if (!(target in this.extraPoints)) {
          this.$set(this.extraPoints, target, 0)
        }

        this.extraPoints[target] += pointsPerTarget
      }

      this.markQuestionAnswered(
        `${this.distributionMode.category}-${this.distributionMode.amount}`,
        this.distributionMode.team,
        null
      )

      this.distributionMode = null
    }
  }
}
</script>

<style lang="scss" scoped>
#app .board {
  display: grid;
  grid-template-rows: auto auto repeat(var(--points), 1fr);
  grid-template-columns: repeat(var(--categories), 1fr);
  grid-gap: 1px;
  width: 100vw;
  height: 100vh;

  &__finished {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1;

    &-announcement {
      color: #ccc;
      position: absolute;
      text-align: center;
      width: 68%;
      left: 16%;
      top: 16%;
      font-size: 3.5em;
      font-weight: 600;
    }

    &-fireworks {
      position: absolute;
      width: 100vw;
      height: 100vh;
    }
  }

  &__category-head {
    font-size: calc(1.25rem + 0.25vw / var(--teams));
    background-color: #fafafa;
    padding: 0.6em;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  &__turn-indicator {
    color: inherit;
    margin-top: -0.1em;
    animation: turn-indicator-floating 0.75s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    opacity: 0;
  }

  &__team {
    flex-grow: 1;
    padding: 0.5em;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--team-color);

    &--turn .board__turn-indicator {
      opacity: 0.7;
    }
  }

  &__header {
    grid-row: 1 / span 1;
    grid-column: 1 / span var(--categories);

    display: flex;
    justify-content: stretch;

    font-size: calc(1.25rem + 0.5vw / var(--teams));
  }

  &__field {
    font: inherit;
    color: inherit;
    background: none;
    border: none;
    margin: 0;
    padding: 0 40px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr auto;
    grid-template-areas: 'incorrect points correct' 'incorrect distribute correct';
    justify-content: space-evenly;
    align-items: center;
    line-height: 1;

    &--active {
      background-color: #fafafa;
      outline: 2px dashed var(--current-team-color);

      &:focus {
        outline: 2px dashed var(--current-team-color) !important;
      }

      .board__field-confirm,
      .board__field-distribute {
        display: block;
      }
    }

    &--omitted {
      opacity: 0.33;
    }

    &--answered {
      background-color: var(--answered-background);
      color: var(--answered-foreground);

      &-correct {
      }

      &-incorrect {
        opacity: 0.3;
      }
    }

    &-points {
      font-size: calc(1rem + 10vw / var(--categories));
      font-weight: 700;
      grid-area: points;
      grid-row: 1 / -1;
    }

    &-distribute {
      grid-area: distribute;
      justify-self: center;
      display: none;
    }

    &-confirm {
      display: none;
      font-size: calc(1rem + 6vw / var(--categories));
      width: 1.5em;
      height: 1.5em;

      &--correct {
        color: #14a26f;
        grid-area: correct;
      }

      &--incorrect {
        color: #da3461;
        grid-area: incorrect;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .fontsize-icon {
    width: 1em;
    height: 1em;
    font-size: 1em;
  }

  &__distribute {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    gap: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-headline {
      font-size: 40px;
      color: #fff;
    }

    &-teams {
      display: flex;
      width: fit-content;
      margin: 0 auto;
      gap: 30px;
    }

    &-team {
      border-radius: 4px;
      background-color: var(--team-color);
      padding: 30px 40px;
      font-size: 30px;
      outline-style: none;
      outline-color: var(--team-color);
      outline-width: 3px;
      outline-offset: 4px;

      &--active {
        outline-style: solid;
      }
    }

    &-finish {
      display: flex;
      gap: 20px;
    }

    &-button {
      color: #fff;
      height: 80px;
      width: 80px;
      font-size: 40px;
    }
  }
}

@keyframes turn-indicator-floating {
  from {
    transform: translateX(-50%) scaleY(0.9) scaleX(1.1);
  }

  to {
    transform: translateX(-0%);
  }
}
</style>
