<template>
  <div id="app">
    <v-app v-if="!playing">
      <v-row style="margin: auto; max-width: 650px; padding: 1em">
        <v-col>
          <h1>Der große Preis</h1>
          <p>Spielgenerator</p>
          <h2>Teams</h2>
          <p v-if="teams.length === 0">Noch keine Teams angelegt</p>
          <v-card v-else class="py-3" tile>
            <Draggable
              v-model="teams"
              handle=".draghandle"
              ghost-class="v-list__tile--highlighted"
              :animation="200"
            >
              <transition-group>
                <v-list-item
                  v-for="team in teams"
                  :key="team.id"
                  style="background-color: white"
                >
                  <v-list-item-content>
                    <v-text-field
                      style="margin-bottom: -0.5em"
                      v-model="team.name"
                      dense
                    />
                  </v-list-item-content>

                  <v-list-item-action style="flex-direction: row">
                    <div
                      class="overlay"
                      v-if="showColorPicker === team"
                      @click="
                        showColorPicker = null
                        team.color = pickerColor
                      "
                    />
                    <v-color-picker
                      v-if="showColorPicker === team"
                      v-model="pickerColor"
                      :hide-inputs="true"
                      :hide-mode-switch="true"
                      :swatches="swatches"
                      show-swatches
                      mode="rgba"
                      style="position: absolute; right: 0.5em; z-index: 999"
                    />

                    <v-btn
                      @click="
                        showColorPicker = team
                        pickerColor = team.color
                      "
                      :style="{ background: team.color }"
                      icon
                    >
                      <v-icon :color="getTextColor(team.color)"
                        >mdi-eyedropper</v-icon
                      >
                    </v-btn>
                    <v-btn class="draghandle" icon>
                      <v-icon color="gray lighten-1">mdi-arrow-all</v-icon>
                    </v-btn>
                    <v-btn @click="teams.splice(teams.indexOf(team), 1)" icon>
                      <v-icon color="red lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </transition-group>
            </Draggable>
          </v-card>

          <div class="my-2">
            <v-btn
              color="secondary"
              @click="
                teams.push({
                  id: uuid(),
                  name: getTeamName(),
                  color: getRandomSwatchColor()
                })
              "
            >
              Team hinzufügen
            </v-btn>
          </div>

          <h2>Kategorien</h2>
          <p v-if="categories.length === 0">Noch keine Kategorien angelegt</p>
          <v-card v-else class="py-3" tile>
            <Draggable
              v-model="categories"
              handle=".draghandle"
              ghost-class="v-list__tile--highlighted"
              :animation="200"
            >
              <transition-group>
                <v-list-item v-for="category in categories" :key="category.id">
                  <v-list-item-content>
                    <v-text-field
                      style="margin-bottom: -0.5em"
                      v-model="category.name"
                      dense
                    />
                  </v-list-item-content>

                  <v-list-item-action style="flex-direction: row">
                    <v-btn class="draghandle" icon>
                      <v-icon color="gray lighten-1">mdi-arrow-all</v-icon>
                    </v-btn>
                    <v-btn
                      @click="
                        categories.splice(categories.indexOf(category), 1)
                      "
                      icon
                    >
                      <v-icon color="red lighten-1">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </transition-group>
            </Draggable>
          </v-card>

          <div class="my-2">
            <v-btn
              color="secondary"
              @click="
                categories.push({
                  id: uuid(),
                  name: getCategoryName()
                })
              "
            >
              Kategorie hinzufügen
            </v-btn>
          </div>

          <h2>Punkte</h2>
          <v-container fluid>
            <v-card tile class="px-4 py-4">
              <v-combobox
                :value="points"
                @input="points => updatePoints(points)"
                :items="[]"
                multiple
                chips
                deletable-chips
                label="Punkte"
              />
            </v-card>
          </v-container>

          <h2>Regeln</h2>
          <v-container fluid>
            <v-card tile class="px-4 py-4">
              <v-checkbox
                v-model="rules.onFailureAwardOpponents"
                label="Bei falscher Antwort gehen die Punkte an das/die Gegnerteam/s"
              />
              <v-checkbox
                v-model="rules.clampTurns"
                :label="`Es werden nur so viele Runden gespielt, dass jedes Team die gleiche Anzahl von Fragen beantworten kann (${
                  this.categories.length * this.points.length -
                  ((this.categories.length * this.points.length) %
                    this.teams.length)
                })`"
              />
            </v-card>
          </v-container>

          <v-container fluid>
            <v-btn
              color="primary"
              large
              @click="playing = true"
              :disabled="
                categories.length === 0 ||
                teams.length === 0 ||
                points.length === 0
              "
              >Spiel starten</v-btn
            >
          </v-container>
        </v-col>
      </v-row>
    </v-app>
    <PlayGame
      v-else
      ref="game"
      :points="points.map(Number)"
      :teams="teams"
      :categories="categories.map(category => category.name)"
      :rules="rules"
      @statechange="playState = $event"
    />
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { debounce } from 'lodash-es'
import PlayGame from './components/PlayGame.vue'
import getTextColor from './assets/get-text-color'
import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent
} from 'lz-string'

const uuid = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )

function arraysEqual(a, b) {
  if (a.length !== b.length) return false
  if (a.length === 0) return true
  if (Array.isArray(a[0])) {
    if (Array.isArray(b[0])) {
      return arraysEqual(a.slice(1), b.slice(1))
    } else {
      return false
    }
  }
  if (
    a === null ||
    ['undefined', 'string', 'number', 'boolean', 'symbol', 'bigint'].includes(
      typeof a
    )
  )
    return a === b
  return JSON.stringify(a) === JSON.stringify(b)
}
;[arraysEqual]

export default {
  name: 'app',
  data: () => ({
    justLoadedState: false,
    pickerColor: null,
    showColorPicker: null,
    playing: false,
    points: ['100', '200', '300', '400', '500'],
    playState: null,
    categories: [
      'Kategorie 1',
      'Kategorie 2',
      'Kategorie 3',
      'Kategorie 4'
    ].map(name => ({
      id: uuid(),
      name
    })),
    teams: [
      { id: uuid(), name: 'Team 1', color: '#362ABF' },
      { id: uuid(), name: 'Team 2', color: '#FD5315' }
    ],
    rules: {
      onFailureAwardOpponents: true,
      clampTurns: true
    },

    swatches: [
      ['#FD5315', '#E4D804', '#22B2A5'],
      ['#D2521C', '#F9F8A9', '#2C96D3'],
      ['#835C2F', '#A6C087', '#362ABF'],
      ['#FFF0BD', '#4BC846', '#E255DD'],
      ['#F0C000', '#93F8B8', '#E2127D']
    ]
  }),
  computed: {
    gameState() {
      return {
        playing: this.playing,
        teams: this.teams,
        rules: this.rules,
        categories: this.categories,
        points: this.points,
        playState: this.playState
      }
    }
  },
  components: {
    Draggable,
    PlayGame
  },
  watch: {
    gameState: {
      handler() {
        if (this.justLoadedState) return
        this.debouncedSaveState()
      },
      deep: true
    }
  },
  mounted() {
    this.debouncedSaveState = debounce(this.saveState, 15)
    this.loadState()

    window.addEventListener('popstate', this.loadState)
  },
  destroyed() {
    window.removeEventListener('popstate', this.loadState)
  },
  methods: {
    saveState() {
      let state = this.gameState
      let urlState = compressToEncodedURIComponent(JSON.stringify(state))
      history.pushState(state, '', `#${urlState}`)
    },
    loadState() {
      let urlState = location.hash.slice(1)
      if (urlState.length === 0) return

      this.justLoadedState = true

      setTimeout(() => {
        this.justLoadedState = false
      }, 0)

      let state = JSON.parse(decompressFromEncodedURIComponent(urlState))
      Object.assign(this, state)

      if (this.playing) {
        this.$nextTick(() => {
          console.log('PLAY STATE', state.playState)
          Object.assign(this.$refs.game, state.playState)
        })
      }
    },
    getTextColor,
    uuid,
    getTeamName() {
      const names = this.teams.map(team => team.name)
      let i = this.teams.length + 1
      while (names.includes(`Team ${i}`)) {
        i++
      }
      return `Team ${i}`
    },
    getRandomSwatchColor() {
      if (this.teams.length >= 15) {
        return '#000000'
      }

      let color
      do {
        color = this.swatches[this.rand(0, 4)][this.rand(0, 2)]
      } while (this.teams.some(team => team.color === color))

      return color
    },
    getCategoryName() {
      const names = this.categories.map(category => category.name)
      let i = this.categories.length + 1
      while (names.includes(`Kategorie ${i}`)) {
        i++
      }
      return `Kategorie ${i}`
    },
    rand(from, to) {
      return Math.floor(Math.random() * (to - from + 1)) + from
    },
    updatePoints(value) {
      this.points = value
        .map(Number)
        .sort((a, b) => a - b)
        .filter((item, index, self) => self.indexOf(item) === index)
    },
    updateTeams(teams) {
      if (teams.some(item => typeof item === 'string')) {
        this.teams = teams.map(team => {
          if (typeof team === 'string') {
            return {
              name: team,
              color: '#ffffff'
            }
          } else {
            return team
          }
        })
      }
    }
  }
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

#app .theme--light.v-application {
  background-color: #fafafa;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

#app {
  color: #666b79;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h2 {
  margin-top: 2em;
  margin-bottom: 1em;
}

hr {
  margin: 2em 0;
}

.flip-list-move {
  transition: transform 0.5s !important;
}
</style>
