var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(!_vm.playing)?_c('v-app',[_c('v-row',{staticStyle:{"margin":"auto","max-width":"650px","padding":"1em"}},[_c('v-col',[_c('h1',[_vm._v("Der große Preis")]),_c('p',[_vm._v("Spielgenerator")]),_c('h2',[_vm._v("Teams")]),(_vm.teams.length === 0)?_c('p',[_vm._v("Noch keine Teams angelegt")]):_c('v-card',{staticClass:"py-3",attrs:{"tile":""}},[_c('Draggable',{attrs:{"handle":".draghandle","ghost-class":"v-list__tile--highlighted","animation":200},model:{value:(_vm.teams),callback:function ($$v) {_vm.teams=$$v},expression:"teams"}},[_c('transition-group',_vm._l((_vm.teams),function(team){return _c('v-list-item',{key:team.id,staticStyle:{"background-color":"white"}},[_c('v-list-item-content',[_c('v-text-field',{staticStyle:{"margin-bottom":"-0.5em"},attrs:{"dense":""},model:{value:(team.name),callback:function ($$v) {_vm.$set(team, "name", $$v)},expression:"team.name"}})],1),_c('v-list-item-action',{staticStyle:{"flex-direction":"row"}},[(_vm.showColorPicker === team)?_c('div',{staticClass:"overlay",on:{"click":function($event){_vm.showColorPicker = null
                      team.color = _vm.pickerColor}}}):_vm._e(),(_vm.showColorPicker === team)?_c('v-color-picker',{staticStyle:{"position":"absolute","right":"0.5em","z-index":"999"},attrs:{"hide-inputs":true,"hide-mode-switch":true,"swatches":_vm.swatches,"show-swatches":"","mode":"rgba"},model:{value:(_vm.pickerColor),callback:function ($$v) {_vm.pickerColor=$$v},expression:"pickerColor"}}):_vm._e(),_c('v-btn',{style:({ background: team.color }),attrs:{"icon":""},on:{"click":function($event){_vm.showColorPicker = team
                      _vm.pickerColor = team.color}}},[_c('v-icon',{attrs:{"color":_vm.getTextColor(team.color)}},[_vm._v("mdi-eyedropper")])],1),_c('v-btn',{staticClass:"draghandle",attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"gray lighten-1"}},[_vm._v("mdi-arrow-all")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.teams.splice(_vm.teams.indexOf(team), 1)}}},[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-delete")])],1)],1)],1)}),1)],1)],1),_c('div',{staticClass:"my-2"},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.teams.push({
                id: _vm.uuid(),
                name: _vm.getTeamName(),
                color: _vm.getRandomSwatchColor()
              })}}},[_vm._v(" Team hinzufügen ")])],1),_c('h2',[_vm._v("Kategorien")]),(_vm.categories.length === 0)?_c('p',[_vm._v("Noch keine Kategorien angelegt")]):_c('v-card',{staticClass:"py-3",attrs:{"tile":""}},[_c('Draggable',{attrs:{"handle":".draghandle","ghost-class":"v-list__tile--highlighted","animation":200},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}},[_c('transition-group',_vm._l((_vm.categories),function(category){return _c('v-list-item',{key:category.id},[_c('v-list-item-content',[_c('v-text-field',{staticStyle:{"margin-bottom":"-0.5em"},attrs:{"dense":""},model:{value:(category.name),callback:function ($$v) {_vm.$set(category, "name", $$v)},expression:"category.name"}})],1),_c('v-list-item-action',{staticStyle:{"flex-direction":"row"}},[_c('v-btn',{staticClass:"draghandle",attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"gray lighten-1"}},[_vm._v("mdi-arrow-all")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.categories.splice(_vm.categories.indexOf(category), 1)}}},[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-delete")])],1)],1)],1)}),1)],1)],1),_c('div',{staticClass:"my-2"},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.categories.push({
                id: _vm.uuid(),
                name: _vm.getCategoryName()
              })}}},[_vm._v(" Kategorie hinzufügen ")])],1),_c('h2',[_vm._v("Punkte")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-4 py-4",attrs:{"tile":""}},[_c('v-combobox',{attrs:{"value":_vm.points,"items":[],"multiple":"","chips":"","deletable-chips":"","label":"Punkte"},on:{"input":function (points) { return _vm.updatePoints(points); }}})],1)],1),_c('h2',[_vm._v("Regeln")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-4 py-4",attrs:{"tile":""}},[_c('v-checkbox',{attrs:{"label":"Bei falscher Antwort gehen die Punkte an das/die Gegnerteam/s"},model:{value:(_vm.rules.onFailureAwardOpponents),callback:function ($$v) {_vm.$set(_vm.rules, "onFailureAwardOpponents", $$v)},expression:"rules.onFailureAwardOpponents"}}),_c('v-checkbox',{attrs:{"label":("Es werden nur so viele Runden gespielt, dass jedes Team die gleiche Anzahl von Fragen beantworten kann (" + (this.categories.length * this.points.length -
                ((this.categories.length * this.points.length) %
                  this.teams.length)) + ")")},model:{value:(_vm.rules.clampTurns),callback:function ($$v) {_vm.$set(_vm.rules, "clampTurns", $$v)},expression:"rules.clampTurns"}})],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-btn',{attrs:{"color":"primary","large":"","disabled":_vm.categories.length === 0 ||
              _vm.teams.length === 0 ||
              _vm.points.length === 0},on:{"click":function($event){_vm.playing = true}}},[_vm._v("Spiel starten")])],1)],1)],1)],1):_c('PlayGame',{ref:"game",attrs:{"points":_vm.points.map(Number),"teams":_vm.teams,"categories":_vm.categories.map(function (category) { return category.name; }),"rules":_vm.rules},on:{"statechange":function($event){_vm.playState = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }